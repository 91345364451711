import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "sv-career",
  "bg": "/img/bg-career.jpg",
  "title": "Jobba på Zimply",
  "seo": {
    "title": "-",
    "descr": "-"
  },
  "textBlocks": [{
    "text": "Hos oss på Zimply Innovation erbjuder vi dig som anställd att växa tillsammans med företaget och en möjlighet att påverka företagets framtid. Vi är ett ungt entreprenöriellt företag med stora ambitioner och vi expanderar snabbt. Vi erbjuder kompetensutveckling och arbete inom ny innovativ teknik med många intressanta projekt. Våra anställdas välmående och kompetensutveckling är av högsta prioritet för att kunna attrahera och behålla de allra bästa specialisterna inom området."
  }, {
    "text": "Arbetsplatsen har en inkluderande och transparent kultur och är belägen i vackra lokaler i hjärtat av Stockholm."
  }, {
    "text": "På Zimply värdesätter vi att man har kul på jobbet. Oavsett om det gäller att träna, spela pingis, resa eller att utveckla digitala assistenter tillsammans. Work-Life balance är viktigt för oss och vi tror det är det enda sättet att fortsätta ha energi och fokus för att kunna leverera det bästa resultatet."
  }, {
    "text": "Är du motiverad, jordnära, fokuserad och har erfarenhet från intelligent automation, RPA, AI, IPA, Kognitiv automation, Chatbots eller Machine Learning? Vi vill gärna komma i kontakt med dig. Maila, ring eller kom förbi oss på vårt kontor!"
  }],
  "quoteSection": {
    "title": "Vad är det bästa med att arbeta på Zimply?",
    "text": "“Möjligheten att påverka och att vi har grymt kul tillsammans. Sedan jag började har jag haft ett stort kunskapsutbyte från kunniga utvecklare och här lär man sig det som ligger i teknisk framkant inom AI och machine learning.”",
    "personImage": "/img/sebastian.jpg",
    "personName": "Sebastian André",
    "role": "Product Manager & Developer"
  },
  "servicesTitle": "Sök några av våra tjänster här",
  "servicesBg": "/img/pink.png",
  "servicesSection": [{
    "title": "Business Analyst",
    "text": "Vi söker en analytisk och självgående Business analyst",
    "path": "career/business-analyst"
  }, {
    "title": "Intelligent Automation Developer",
    "text": "Vi söker en kreativ och driven  utvecklare för att utöka vårt team",
    "path": "career/intelligent-automation-developer"
  }, {
    "title": "Sales executive",
    "text": "-",
    "path": "career/sales-executive"
  }],
  "endSection": {
    "title": "Läs mer om Zimply",
    "icon": "/img/bulb.png",
    "buttonText": "Gå till",
    "buttonPath": "about"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      